<template>
    <vx-card title="Wills" :subtitle="subTitle" class="no-over">
        <div class="flex">
            <div class="mr-auto">
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
            <div>
                <vs-dropdown
                    vs-trigger-click
                    class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                >
                    <div
                        class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium border-grey"
                    >
                        <span class="mr-2"> Top {{ numberOfBrokers }} </span>
                        <feather-icon
                            icon="ChevronDownIcon"
                            svgClasses="h-4 w-4"
                        />
                    </div>

                    <vs-dropdown-menu
                        class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                    >
                        <vs-dropdown-item v-on:click="numberOfBrokers = 10">
                            10
                        </vs-dropdown-item>

                        <vs-dropdown-item v-on:click="numberOfBrokers = 20">
                            20
                        </vs-dropdown-item>

                        <vs-dropdown-item
                            v-on:click="numberOfBrokers = serverData.length"
                        >
                            All
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-full">
                <vue-apex-charts
                    type="bar"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                ></vue-apex-charts>
            </div>
        </div>
        <vs-divider></vs-divider>

        <vs-table v-if="serverData" :data="serverData">
            <template slot="header">
                <h3>Brokers</h3>
            </template>
            <template slot="thead">
                <vs-th sort-key="company_name"> Company </vs-th>
                <vs-th sort-key="wills_all"> Wills Total </vs-th>
                <vs-th sort-key="wills_from_electronic">
                    Wills Elec Coupons
                </vs-th>
                <vs-th sort-key="wills_from_printed">
                    Wills Print Coupons
                </vs-th>
                <vs-th sort-key="users"> Users </vs-th>
                <vs-th sort-key="emails_sent"> Emails sent </vs-th>
                <vs-th sort-key="sms_sent"> Sms sent </vs-th>
                <vs-th sort-key="invitations"> Invitations Elec </vs-th>
                <vs-th> Conversions Elec </vs-th>
            </template>
            <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].company_name">
                        <span
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: data[indextr].email },
                                })
                            "
                            >{{ data[indextr].company_name }}</span
                        >
                    </vs-td>

                    <vs-td :data="data[indextr].wills_all">
                        {{ data[indextr].wills_all }}
                    </vs-td>

                    <vs-td :data="data[indextr].wills_from_electronic">
                        {{ data[indextr].wills_from_electronic }}
                    </vs-td>
                    <vs-td :data="data[indextr].wills_from_printed">
                        {{ data[indextr].wills_from_printed }}
                    </vs-td>

                    <vs-td :data="data[indextr].users">
                        {{ data[indextr].users }}
                    </vs-td>
                    <vs-td :data="data[indextr].emails_sent">
                        {{ data[indextr].emails_sent }}
                    </vs-td>
                    <vs-td :data="data[indextr].sms_sent">
                        {{ data[indextr].sms_sent }}
                    </vs-td>
                    <vs-td :data="data[indextr].invitations">
                        {{ data[indextr].invitations }}
                    </vs-td>
                    <vs-td>
                        {{ conversions(data[indextr]) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>
import { http } from '@/services'
import VueApexCharts from 'vue-apexcharts'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'BrokerReportWillsCard',
    components: {
        VueApexCharts,
        DatePicker,
    },
    mounted() {
        this.brokerWillsReportFetch()
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.start && value.end) {
                    this.brokerWillsReportFetch()
                }
            },
        },
    },
    computed: {
        subTitle() {
            if (this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
        series() {
            return [
                {
                    name: 'Wills Created',
                    data: this.willsCreated,
                },
                {
                    name: 'Users Created',
                    data: this.usersCreated,
                },
            ]
        },
        chartOptions() {
            return {
                colors: this.themeColors,
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                        columnWidth: '55%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent'],
                },

                xaxis: {
                    categories: this.brokers,
                },
                yaxis: {
                    title: {
                        text: '',
                    },
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        },
                    },
                },
            }
        },
        sortedDataGraph() {
            if (this.serverData)
                return [...this.serverData]
                    .sort((a, b) => b.wills_all - a.wills_all)
                    .slice(0, this.numberOfBrokers)
            return []
        },

        brokers() {
            return this.sortedDataGraph
                ? this.sortedDataGraph.map((broker) => broker.company_name)
                : []
        },
        willsCreated() {
            return this.serverData
                ? this.sortedDataGraph.map((broker) => broker.wills_all)
                : []
        },
        usersCreated() {
            return this.serverData
                ? this.sortedDataGraph.map((broker) => broker.users)
                : []
        },
    },
    data() {
        return {
            range: {
                start: null,
                end: null,
            },
            numberOfBrokers: 10,
            serverData: null,
            themeColors: [
                '#7367F0',
                '#28C76F',
                '#EA5455',
                '#FF9F43',
                '#1E1E1E',
            ],
            columnChart: {},
        }
    },
    methods: {
        brokerWillsReportFetch() {
            http.get('report_broker_wills', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.serverData = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        conversions(data) {
            console.log(data)
            if (data.wills_from_electronic && data.invitations) {
                return (
                    Number(
                        (data.wills_from_electronic / data.invitations) * 100
                    ).toFixed(0) + '%'
                )
            } else {
                return '0%'
            }
        },
    },
}
</script>

<style></style>
