<template>
    <div id="willOverview">
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <BrokerReportWillsGraph />
            </div>
        </div>
    </div>
</template>

<script>
import { http, httpGoCardless } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import BrokerReportWillsGraph from '../Cards/BrokerReportWillsGraph'

export default {
    name: 'ReportBrokers',
    mixins: [textFormat],
    components: {
        BrokerReportWillsGraph,
    },
    props: [],
    created() {
        this.reportDataFetch()
    },
    beforeRouteUpdate(to, from, next) {
        this.reportDataFetch()
        next()
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    computed: {
        subTitle() {
            if (this.fromDate && this.toDate)
                return `${this.fromDate.toDateString()} to ${this.toDate.toDateString()}`
            return 'Last 28 days'
        },
    },
    data() {
        return {
            data: null,
            backPath: null,
            fromDate: null,
            toDate: null,
        }
    },
    methods: {
        reportDataFetch(id) {
            this.$vs.loading()
            var data = {
                from_date: this.fromDate,
                to_date: this.toDate,
            }
            http.get('report_share', { params: data })
                .then((response) => {
                    this.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
    },
}
</script>

<style scoped>
.no-over {
    overflow: visible;
}
</style>
